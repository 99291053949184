<template>
    <div class=" has-text-weight-semibold">
        <div class="is-large has-background-gray has-text-centered">

            <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                <div class="columns is-mobile">
                    <div class="column is-size-6 has-text-centered">DAILY SALES SUMMARY</div>
                    <div class="level-right has-text-centered">
                        <!-- <b-button class="is-dark is-small is-hidden-mobile" @click="printDaysReport('all')">Print</b-button> -->
                        &nbsp;&nbsp;
                        <b-button title="Export to Excel" v-if="getSettingUserAuthorized('settingsAuthorizedPayroll')" type="is-primary" outlined class="is-small is-hidden-mobile" @click="jsonAsXlsx">
                            <!-- <img src="/images/spreadsheet.png" width="20" height="20" alt="" class="is-rounded"> -->
                            <i class="fas fa-file-excel"></i>
                        </b-button>
                    </div>                    
                </div>
            </div>
            
            <div class="columns">
                <div class="column is-4 has-text-left">
                    <b-field>
                        <date-range-selector
                            :dateRange="salesDateRange"
                            @set-date-range="setDateRange"
                            >
                        </date-range-selector>                    
                    </b-field>                    
                </div>

            </div> 
        </div>

        <hr>

        <div class="columns topalign" v-if="this.totalSales.netSales > 0.00">
            <div class="column has-text-centered topalign has-text-weight-semibold">
                <article class="box">
                <div class="fullwidth">
                <p class="is-size-4 has-text-weight-bold boxheader">Net Sales</p>

                <div class="columns is-mobile has-text-left">
                    <div class="column is-6s">Credit Card</div>
                    <div class="column has-text-right">{{ this.totalSales.creditAmount | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile has-text-left">
                    <div class="column is-6">Debit Card</div>
                    <div class="column has-text-right">{{ this.totalSales.debitAmount | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile has-text-left" v-if="this.totalSales.ebtAmount.toFixed(2) != 0.00">
                    <div class="column is-6">EBT</div>
                    <div class="column has-text-right">{{ this.totalSales.ebtAmount | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile has-text-left">
                    <div class="column is-6">Cash</div>
                    <div class="column has-text-right">{{ this.totalSales.cashAmount | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile has-text-left" v-if="this.totalSales.inHouseAmount.toFixed(2) != 0.00">
                    <div class="column is-6">House Account</div>
                    <div class="column has-text-right">{{ this.totalSales.inHouseAmount | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile has-text-left" v-if="this.totalSales.couponAmount.toFixed(2) != 0.00">
                    <div class="column is-6">Coupon</div>
                    <div class="column has-text-right">{{ this.totalSales.couponAmount | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile has-text-left" v-if="this.totalSales.otherPayment.toFixed(2) != 0.00">
                    <div class="column is-6">Other</div>
                    <div class="column has-text-right">{{ this.totalSales.otherPayment | formatCurrency }}</div>
                </div>                   
                <div class="columns is-mobile has-text-left" v-if="this.totalSales.cancelRefund.toFixed(2) != 0.00">
                    <div class="column is-6">Cancel/Refund</div>
                    <div class="column has-text-right has-text-danger">{{ (this.totalSales.cancelRefund) * -1 | formatCurrency }}</div>
                </div>                             
                <div class="columns is-mobile bordertop">
                    <div class=" is-6 has-text-left column has-text-weight-bold">Total</div>
                    <div class="column has-text-right has-text-weight-bold">{{ this.totalSales.netSales| formatCurrency }}</div>
                    <!-- <div class="column has-text-right has-text-weight-bold">{{ this.totalSales.creditAmount + this.totalSales.debitAmount + this.totalSales.lineCardAmount + this.totalSales.cashAmount + this.totalSales.otherPayment - this.totalSales.cancelRefund| formatCurrency }}</div> -->
                </div>

                </div>
                </article>
            </div>

            <div class="column has-text-centered topalign has-text-weight-semibold">
                <article class="box">                
                <div class="fullwidth">
                    <p class="is-size-4 has-text-weight-bold boxheader">Merchandise Sales</p> 
                </div>               
                <div :key="mapping.value" v-for="mapping in storeMappings">
                    <div class="columns is-mobile has-text-left" v-if="totalSales[mapping.value] != 0.00">
                        <div class="column">{{ mapping.name }}</div>
                        <div class="column has-text-right">{{ totalSales[mapping.value] | formatCurrency }}</div>
                    </div>
                </div>
                <div class="columns is-mobile has-text-left" v-if="(totalSales.merchSales - totalSales['NoDept']).toFixed(2) != 0.00">
                    <div class="column">No Dept.</div>
                    <div class="column has-text-right">{{ totalSales.merchSales.toFixed(2) - totalSales['NoDept'].toFixed(2) | formatCurrency }}</div>
                </div>                
                <div class="columns is-mobile bordertop">
                    <div class="column has-text-left is-size-5 has-text-weight-bold">Total</div>
                    <div class="column has-text-right is-size-5 has-text-weight-bold">{{ totalSales.merchSales | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile bordertop">
                    <div class="column has-text-left has-text-weight-bold">Tax</div>
                    <div class="column has-text-right has-text-weight-bold">{{ totalSales.totalTaxes | formatCurrency }}</div>
                </div>                
                <div class="columns is-mobile has-text-left bordertop">
                    <div class="has-text-left column has-text-weight-bold">Void Line </div>
                    <div class="column has-text-right has-text-weight-bold  has-text-danger">{{ totalSales.voidLineAmount * -1 | formatCurrency }}</div>
                </div>
                </article>                                                                                   
            </div>

            <div class="column has-text-centered has-text-weight-semibold">
                <article class="box">                
                <div class="fullwidth">
                    <p class="is-size-4 has-text-weight-bold boxheader">Fuel Sales</p>
                    <div class="columns is-mobile has-text-left" v-for="item in totalSales.fuel" v-bind:key="item.id">
                        <div class="column">{{ item.fuelProduct }} (gal)</div>
                        <div class="column has-text-right">{{ item.volume | formatFuelVolumeSimple }}</div>                            
                    </div>
                    <div class="columns is-mobile has-text-left bordertop">
                        <div class="column has-text-weight-bold">Total Volume (gal)</div>
                        <div class="column has-text-right has-text-weight-bold">{{ totalSales.fuelTotalVol | formatFuelVolumeSimple }}</div>
                    </div>
                    <div class="columns is-mobile has-text-left bordertop">
                        <div class="column has-text-weight-bold">Fuel Sales</div>
                        <div class="column has-text-right has-text-weight-bold">{{ totalSales.fuelSales | formatCurrency }}</div>
                    </div>
                </div>
                </article>
            </div>
            <div class="column has-text-centered has-text-weight-semibold">
                <article class="box">                
                <div class="fullwidth">
                <p class="is-size-4 has-text-weight-bold boxheader">Cash</p>

                <div class="columns is-mobile has-text-left">
                    <div class="column">Cash Payments</div>
                    <div class="column has-text-right">{{ totalSales.cashAmount | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile has-text-left">
                    <div class="column">Paid In</div>
                    <div class="column has-text-right">{{ totalSales.payIn | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile has-text-left">
                    <div class="column">Paid Out</div>
                    <div class="column has-text-right has-text-danger">{{ totalSales.payOut * -1 | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile has-text-left">
                    <div class="column">Cash Refund </div>
                    <div class="column has-text-right has-text-danger">{{ totalSales.cashRefundAmount * -1 | formatCurrency }}</div>
                </div>               
                <div class="columns is-mobile bordertop">
                    <div class="has-text-left column has-text-weight-bold">Cash Balance</div>
                    <div class="column has-text-right has-text-weight-bold">{{ totalSales.cashAmount + totalSales.payIn - totalSales.payOut - totalSales.cashRefundAmount | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile has-text-left">
                    <div class="column">Safe Drop</div>
                    <div class="column has-text-right has-text-danger">{{ (totalSales.totalPaymentOut - totalSales.payOut) * -1 | formatCurrency }}</div>
                </div>
                <div class="columns is-mobile bordertop">
                    <div class="has-text-left column has-text-weight-bold">Net Cash On Hand</div>
                    <div class="column has-text-right has-text-weight-bold">{{ totalSales.cashAmount + totalSales.payIn - totalSales.totalPaymentOut - totalSales.cashRefundAmount | formatCurrency }}</div>
                </div>                          
                </div>
                </article>
            </div>
        </div>                 

        <hr>

        <b-table v-if="dailySales"
            class="has-text-weight-semibold"
            :visible="!isLoading"
            :data='dailySales'
            striped
            :scrollable="isScrollable"
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="date"
            default-sort-direction="desc"
            :show-detail-icon="showDetailIcon"
            detailed
            detail-key="date"
            @details-open="(row) => $buefy.toast.open(`Expanded ${row.date}`)"            
            >

            <template>
           
                <b-table-column class="is-2" field="date" label="Date" v-slot="props" sortable sorticon>
                    {{ props.row.date }}
                </b-table-column>
            
                <b-table-column numeric field="netSales" label="Net Sales" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.netSales | formatCurrency}}
                </b-table-column>

                <b-table-column numeric field="fuelTotalVol" label="Fuel Volume" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.fuelTotalVol | formatFuelVolumeSimple }}
                </b-table-column>

                <b-table-column numeric field="fuelSales" label="Fuel Sales" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.fuelSales | formatCurrency }}
                </b-table-column>

                <b-table-column numeric field="merchSales" label="Merch Sales" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.merchSales | formatCurrency }}
                </b-table-column>

                <b-table-column numeric field="cashAmount" label="Cash Amount" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.cashAmount  | formatCurrency }}
                </b-table-column>

                <b-table-column numeric field="payIn" label="Pay in" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.payIn  | formatCurrency }}
                </b-table-column>

                <b-table-column numeric field="payOut" label="Pay Out" align="right"  v-slot="props" sortable sorticon>
                     <div class="has-text-danger">{{ props.row.payOut * -1 | formatCurrency }}</div>
                </b-table-column>

                <!-- <b-table-column numeric field="cancelRefund" label="Cancel Refund" align="right" v-slot="props" sortable sorticon>
                    <div class="has-text-danger">{{ props.row.cancelRefund * -1 | formatCurrency }}</div>
                </b-table-column> -->

                <b-table-column numeric field="cancelRefund" label="Cancel Refund" align="right" v-slot="props" sortable sorticon>
                    <div class="has-text-danger">{{ props.row.cashRefundAmount * -1 | formatCurrency }}</div>
                </b-table-column>

                <b-table-column numeric field="cashBalance" label="Safe Drop" align="right" v-slot="props" sortable sorticon>
                    {{ (props.row.totalPaymentOut - props.row.payOut) * -1  | formatCurrency }}
                </b-table-column>  

                <b-table-column numeric field="cashBalance" label="Cash Balance" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.cashAmount - props.row.totalPaymentOut  -   props.row.cashRefundAmount | formatCurrency }}
                </b-table-column>  
           
            </template>
            
            <template slot="detail" slot-scope="props">
                
                <div class="columns topalign">
                    <div class="column has-text-centered">
                        <article class="box">                        
                        <div class="fullwidth">
                        <p class="is-size-4 has-text-weight-bold boxheader">Net Sales</p>

                        <div class="columns is-mobile has-text-left">
                            <div class="column">Credit Card</div>
                            <div class="column has-text-right">{{ props.row.creditAmount | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile has-text-left">
                            <div class="column">Debit Card</div>
                            <div class="column has-text-right">{{ props.row.debitAmount | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile has-text-left" v-if="props.row.ebtAmount.toFixed(2) != 0.00">
                            <div class="column">EBT</div>
                            <div class="column has-text-right">{{ props.row.ebtAmount | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile has-text-left">
                            <div class="column">Cash</div>
                            <div class="column has-text-right">{{ props.row.cashAmount | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile has-text-left" v-if="props.row.inHouseAmount.toFixed(2) != 0.00">
                            <div class="column">House Account</div>
                            <div class="column has-text-right">{{ props.row.inHouseAmount | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile has-text-left" v-if="props.row.couponAmount.toFixed(2) != 0.00">
                            <div class="column">Coupon</div>
                            <div class="column has-text-right">{{ props.row.couponAmount | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile has-text-left" v-if="props.row.otherPayment.toFixed(2) != 0.00">
                            <div class="column is-two-thirds">Other</div>
                                <div class="column has-text-right">{{ props.row.otherPayment | formatCurrency}}</div>
                        </div>                   
                        <div class="columns is-mobile has-text-left" v-if="props.row.cancelRefund.toFixed(2) != 0.00">
                            <div class="column">Cancel Refund </div>
                            <div class="column has-text-right has-text-danger">{{ props.row.cancelRefund * -1 | formatCurrency }}</div>
                        </div>

                        <div class="columns is-mobile bordertop">
                            <div class="has-text-left column has-text-weight-bold">Total</div>
                            <div class="has-text-right column has-text-weight-bold">{{ props.row.netSales | formatCurrency }}</div>
                            <!-- <div class="has-text-right column has-text-weight-bold">{{ props.row.creditAmount + props.row.debitAmount + props.row.lineCardAmount + props.row.cashAmount + props.row.otherPayment - props.row.cancelRefund | formatCurrency }}</div> -->
                        </div>

                        </div>
                        </article>
                    </div>

                    <div class="column has-text-centered topalign has-text-weight-semibold">
                        <article class="box">                                                
                        <div class="fullwidth">
                            <p class="is-size-4 has-text-weight-bold boxheader">Merchandise Sales</p> 
                        </div>               
                        <div :key="mapping.value" v-for="mapping in storeMappings">
                            <div class="columns is-mobile has-text-left" v-if="props.row[mapping.value] != 0.00">
                                <div class="column">{{ mapping.name }}</div>
                                <div class="column has-text-right">{{ props.row[mapping.value] | formatCurrency }}</div>
                            </div>
                        </div>
                        <div class="columns is-mobile has-text-left" v-if="(props.row.merchSales - props.row['NoDept']).toFixed(2) != 0.00">
                            <div class="column">No Dept.</div>
                            <div class="column has-text-right">{{ (props.row.merchSales - props.row['NoDept']) | formatCurrency }}</div>
                        </div>                           
                        <div class="columns is-mobile bordertop">
                            <div class="column has-text-left is-size-5 has-text-weight-bold">Total</div>
                            <div class="column has-text-right is-size-5 has-text-weight-bold">{{ props.row.merchSales | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile bordertop">
                            <div class="column has-text-left has-text-weight-bold">Tax</div>
                            <div class="column has-text-right has-text-weight-bold">{{ props.row.totalTaxes | formatCurrency }}</div>
                        </div>                
                        <div class="columns is-mobile has-text-left bordertop">
                            <div class="has-text-left column has-text-weight-bold">Void Line </div>
                            <div class="column has-text-right has-text-weight-bold  has-text-danger">{{ props.row.voidLineAmount * -1 | formatCurrency }}</div>
                        </div>
                        </article>                                                                         
                    </div>                    

                    <div class="column has-text-centered">
                        <article class="box">                        
                        <div class="fullwidth">
                            <p class="is-size-4 has-text-weight-bold boxheader">Fuel Sales</p>


                            <div class="columns is-mobile has-text-left" v-for="item in props.row.fuel" v-bind:key="item.id">
                                <div class="column">{{ item.fuelProduct }} (gal)</div>
                                <div class="column has-text-right">{{ item.volume | formatFuelVolumeSimple }}</div>                            
                            </div>


                            <div class="columns is-mobile has-text-left bordertop">
                                <div class="has-text-left column has-text-weight-bold">Total Volume (gal)</div>
                                <div class="has-text-right column has-text-weight-bold">{{ props.row.fuelTotalVol | formatFuelVolumeSimple }}</div>
                            </div>
                            <div class="columns is-mobile has-text-left bordertop">
                                <div class="has-text-left column has-text-weight-bold">Fuel Sales</div>
                                <div class="has-text-right column has-text-weight-bold">{{ props.row.fuelSales | formatCurrency }}</div>
                            </div>
                        </div>
                        </article>
                    </div>
                    <div class="column has-text-centered">
                        <article class="box">                                                
                        <div class="fullwidth">
                        <p class="is-size-4 has-text-weight-bold boxheader">Cash</p>

                        <div class="columns is-mobile has-text-left">
                            <div class="column">Cash Payments</div>
                            <div class="column has-text-right">{{ props.row.cashAmount | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile has-text-left">
                            <div class="column">Paid In</div>
                            <div class="column has-text-right">{{ props.row.payIn | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile has-text-left">
                            <div class="column">Paid Out</div>
                            <div class="column has-text-right has-text-danger">{{ props.row.payOut * -1 | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile has-text-left">
                            <div class="column">Cash Refund </div>
                            <div class="column has-text-right has-text-danger">{{ props.row.cashRefundAmount * -1 | formatCurrency }}</div>
                        </div>                       
                        <div class="columns is-mobile bordertop">
                            <div class="has-text-left column has-text-weight-bold">Cash Balance</div>
                            <div class="has-text-right column has-text-weight-bold">{{ props.row.cashAmount + props.row.payIn - props.row.payOut - props.row.cashRefundAmount | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile has-text-left">
                            <div class="column">Safe Drop</div>
                            <div class="column has-text-right has-text-danger">{{ (props.row.totalPaymentOut - props.row.payOut) * -1 | formatCurrency }}</div>
                        </div>
                        <div class="columns is-mobile bordertop has-text-left">
                            <div class="has-text-left column has-text-weight-bold">Net Cash On Hand</div>
                            <div class="column has-text-right has-text-weight-bold">{{ props.row.cashAmount + props.row.payIn - props.row.totalPaymentOut - props.row.cashRefundAmount  | formatCurrency }}</div>
                        </div>                                                    
                    
                        </div>
                        </article>
                    </div>
                </div>                 
                
            </template>

            <template slot="footer" v-if="this.totalSales.netSales > 0.00">
                <b-table-column></b-table-column>
                <b-table-column></b-table-column>
                <b-table-column>
                    <div class="is-size-5 has-text-weight-bold is-hidden-mobile">Total</div>
                    <div class="is-size-4 has-text-weight-bold has-text-right is-hidden-desktop">Total</div>
                </b-table-column>  
 
                 <b-table-column numeric field="fuelTotalVol">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Net Sales : </span>
                    <span class="has-text-right  has-text-weight-bold">{{ this.totalSales.netSales | formatCurrency }}</span>
                </b-table-column>

                 <b-table-column numeric field="fuelTotalVol">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Taxes : </span>
                    <span class="has-text-right  has-text-weight-bold">{{ this.totalSales.totalTaxes | formatCurrency }}</span>
                </b-table-column>

                 <b-table-column numeric field="fuelTotalVol">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Cash Refund : </span>
                    <span class="has-text-right  has-text-weight-bold  has-text-danger">{{ this.totalSales.cashRefundAmount * -1 | formatCurrency }}</span>
                </b-table-column>
                 <b-table-column numeric field="fuelTotalVol">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Pay Out : </span>
                    <span class="has-text-right  has-text-weight-bold  has-text-danger">{{ this.totalSales.payOut * -1 | formatCurrency }}</span>
                </b-table-column>

                 <b-table-column numeric field="fuelTotalVol">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Pay In : </span>
                    <span class="has-text-right  has-text-weight-bold">{{ this.totalSales.payIn | formatCurrency }}</span>
                </b-table-column> 

                 <b-table-column numeric field="fuelTotalVol">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Merchandise Sales : </span>
                    <span class="has-text-right  has-text-weight-bold">{{ this.totalSales.merchSales | formatCurrency }}</span>
                </b-table-column> 

                 <b-table-column numeric field="fuelTotalVol">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Inside Sales : </span>
                    <span class="has-text-right  has-text-weight-bold">{{ this.totalSales.merchSales - this.totalSales.beerWine - this.totalSales.spirit - this.totalSales.cigarette | formatCurrency }}</span>
                </b-table-column>

                 <b-table-column numeric field="fuelTotalVol">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Fuel Volume : </span>
                    <span class="has-text-right  has-text-weight-bold">{{ this.totalSales.fuelTotalVol | formatCurrency }}</span>
                </b-table-column> 

                 <b-table-column numeric field="fuelTotalVol">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Fuel Sales : </span>
                    <span class="has-text-right  has-text-weight-bold">{{ this.totalSales.fuelSales | formatCurrency }}</span>
                </b-table-column> 

                 <b-table-column numeric field="fuelTotalVol">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Cash : </span>
                    <span class="has-text-right  has-text-weight-bold">{{ this.totalSales.cashAmount | formatCurrency }}</span>
                </b-table-column> 

                 <b-table-column numeric field="totalCashBalance">
                    <span class="has-text-left is-hidden-desktop  has-text-weight-bold">Cash Balance : </span>
                    <span class="has-text-right  has-text-weight-bold">{{ this.totalSales.cashAmount - this.totalSales.payOut - this.totalSales.cashRefundAmount | formatCurrency }}</span>
                </b-table-column>   

            </template>

            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>
        </b-table>
    </div>
</template>

<script>

import UserStore from "../../../store/UserStore"
import DataLoading from '../../../components/app/DataLoading'
import axios from 'axios'
import DateRangeSelector from "../../../components/app/DateRangeSelector"
import moment from 'moment'
import jsonAsXLSX from 'json-as-xlsx'

export default {

    components: {
        DataLoading,
        DateRangeSelector,
    },

    metaInfo: {
        title: 'Daily Sales Details'
    },

    data() {
        return {
            users: [],
            departmentMappingTotals: [],
            dailySales: [],
            fuelProducts: [],
            deptSales: [],
            totalSales: {},
            isScrollable: true,
            isPaginated: false,
            showDetailIcon: true,
            perPage: 50,
            isLoading: true,
            isFullPage: true,
            isShowDialog: false,
            storeId: 0,
            salesDateRange: [new Date(), new Date()],
            mapTotal: 0.00,
            storeMappings: UserStore.getters.getStoreMappings,
            salesFields: [
                    'date',
                    'creditAmount',
                    'debitAmount',
                    'lineCardAmount',
                    'couponAmount',
                    'inHouseAmount',
                    'linkCardAmount',
                    'foodstampAmount',
                    'netSales',
                    'totalTaxes',
                    'totalSalesTaxes',
                    'totalRefundTaxes',
                    'cancelRefund',
                    'cashRefundAmount',
                    'voidLineAmount',
                    'totalPaymentOut',
                    'payOut',
                    'payIn',
                    'merchSales',
                    'fuelSales',
                    'cashAmount',
                    ],
            chartOptions: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }],
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },        
        } 

    },

    watch: {
        salesDateRange: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.fetchStoreSales()
            } else {
                this.isLoading = false
            }
        }

    },

    methods: {

        fetchStoreSales() {
            if (this.user.activeStore) {

                this.totalSales.netSales = 0.00
                this.dailySales = []
                this.isLoading = true
                this.dataSales = []

                var startDate = this.salesDateRange[0].toISOString().split('T')[0]
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                    + '/sales?size=50'  + "&fromDate="+startDate+"&toDate="+endDate

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        // this.loadDailySales(Object.entries(response.data.data.overallSales))
                        this.loadDailySales(Object.entries(response.data.data))

                        // console.log("Response",response.data.data)

                    })
                    .catch( (err) => {
                        this.isLoading = false
                        if (err.response) {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }                            
                        } else {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err)

                            if (err.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        }
                        
                        this.isLoading = false

                    })
            }
            
        },

        loadDailySales(items) {
            
            this.isLoading = true

            var item

            var emptyFuel = {
                amount: 0.00,
                cashAmount:  0.00,
                cashCount:  0,
                cashVolume:  0.00,
                count:  0,
                createdDate:  '2020-01-01',
                creditAmount:  0.00,
                creditCount:  0,
                creditVolume:  0.000,
                date:  '2020-01-01',
                fuelProduct: "", 
                id:  0,
                lastModifiedDate: '2020-01-01', 
                periodBeginDate: '2020-01-01',
                periodEndDate: '2020-01-01',
                volume: 0.00
            }

            this.totalSales = []

            this.dailySales = []



            for (item of items) {
                
                item.closingDate = item[1].netSalesDTO.closingDate
                
            }

            items.sort(function (a, b) {
                return a.closingDate.localeCompare(b.closingDate);
            });

            for (item of items) {

                var fuelObj = []
                var fuelTotalVol = 0.00

                if (! item[1].netSalesDTO.netSales) {
                    continue
                }

                // Processing Sales Summary

                let itemObj = Object.keys(item[1].netSalesDTO)
                    .filter(key => this.salesFields.indexOf(key) >= 0)
                    .reduce((itemObj, key) => Object.assign(itemObj, { [key]: item[1].netSalesDTO[key] }), {})

                itemObj.lineCardAmount = itemObj.lineCardAmount ? itemObj.lineCardAmount : 0
                itemObj.foodstampAmount = itemObj.foodstampAmount ? itemObj.foodstampAmount : 0
                itemObj.linkCardAmount = itemObj.linkCardAmount ? itemObj.linkCardAmount : 0
                itemObj.inHouseAmount = itemObj.inHouseAmount ? itemObj.inHouseAmount : 0
                itemObj.couponAmount = itemObj.couponAmount ? itemObj.couponAmount : 0
                itemObj.netSales = itemObj.netSales ? itemObj.netSales : 0
                itemObj.creditAmount = itemObj.creditAmount ? itemObj.creditAmount : 0
                itemObj.debitAmount = itemObj.debitAmount ? itemObj.debitAmount : 0
                itemObj.cashAmount = itemObj.cashAmount ? itemObj.cashAmount : 0
                itemObj.cancelRefund = itemObj.cancelRefund ? itemObj.cancelRefund : 0


                itemObj.ebtAmount = itemObj.lineCardAmount 
                                  + itemObj.foodstampAmount 
                                  + itemObj.linkCardAmount

                itemObj.otherPayment = itemObj.netSales 
                                - itemObj.creditAmount 
                                - itemObj.debitAmount 
                                - itemObj.ebtAmount 
                                - itemObj.cashAmount 
                                - itemObj.couponAmount
                                - itemObj.inHouseAmount
                                + itemObj.cancelRefund

                if (item[1].fuelSalesDTO) {

                    var self = this
                    
                    item[1].fuelSalesDTO.forEach(function(fuelSale) {


                        if (self.salesFields.indexOf((fuelSale.fuelProduct)) === -1) {

                            self.salesFields.push(fuelSale.fuelProduct)

                        }
                        fuelObj.push(fuelSale)

                        fuelTotalVol = fuelTotalVol + fuelSale.volume
                    
                        self.fuelProducts[fuelSale.fuelProduct] = fuelSale.volume
                        itemObj[fuelSale.fuelProduct] = fuelSale.volume
                    })
                }

                for (const property in self.fuelProducts) {
                    if (!itemObj[property] ) {

                        itemObj[property] = 1.00

                        emptyFuel.id = new Date().valueOf()
                        emptyFuel.fuelProduct = property
                        fuelObj.push(emptyFuel)
                    }

                }

                // Initialize Sales Summary 
                this.storeMappings.forEach(function(mapping){
                    itemObj[mapping.value] = 0.00
                })
                itemObj["NoDept"] = 0.00

                itemObj["fuelTotalVol"] = fuelTotalVol
                itemObj["fuel"] = fuelObj

                if (item[1].departmentSalesDTO) {

                    item[1].departmentSalesDTO.forEach(function(deptSale) {
                        if (deptSale.storeMapping == null || deptSale.storeMapping == "") {
                            deptSale.storeMapping = "Other"
                        }
                        if (deptSale.storeMapping != "Fuel") {
                            itemObj[deptSale.storeMapping] += parseFloat(deptSale.netSales)    
                        }
                                            
                        
                    })

                }

                this.storeMappings.forEach(function(mapping){
                    itemObj["NoDept"] += itemObj[mapping.value]
                })

                this.dailySales.push(itemObj)

            }
                      
            self.totalSales["fuel"] = []
            this.dailySales.forEach(function(item) {

                for (const [key, value] of Object.entries(item)) {
                    if (key != "fuel") {
                        if (isNaN(self.totalSales[key])) {
                            self.totalSales[key] = value
                        } else {
                            self.totalSales[key] = self.totalSales[key] + value
                        }                
                    }
                }
                  
            })

            for (const property in self.fuelProducts) {
                var itemObj = { "fuelProduct": property, "volume": self.totalSales[property] }
                self.totalSales.fuel.push(itemObj)
            }
            this.isLoading = false

            if (this.dailySales.length > 40) {
                this.isPaginated = true
            } else {
                this.isPaginated = false
            }
        },

        getFuelChartData(chartData, legend) {

            var labels = []
            var bgcolors = []
            this.datasets = []


            chartData.forEach(item => {

                labels.push(item.fuelProduct)
                bgcolors.push('#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6))

                this.datasets.push(item.volume)

            })

            var chartObject = {
                labels: labels,
                datasets: [
                    {
                        label: legend,
                        data: this.datasets,
                        backgroundColor: bgcolors,
                    }
                ],
                options: this.chartOptions
            }
            
            return chartObject

        },

        applyFilter() {
            this.fetchStoreSales()
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },

        jsonAsXlsx() {
            var jsonAsXlsxData = [
                {
                    sheet: "Daily Sales",
                    columns: [
                    { label: "Date", value: "date", format: "MM/DD/YYYY" },
                    { label: "Net Sales", value: "netSales", format: "$#,##0.00"}, 
                    { label: "Fuel Sales", value: "fuelSales", format: "$#,##0.00"},
                    { label: "Fuel Volume", value: "fuelTotalVol", format: "#,##0.000"},
                    { label: "Merch Sales", value: "merchSales", format: "$#,##0.000"},
                    { label: "Taxes", value: "totalTaxes", format: "$#,##0.000"},
                    { label: "Payment Out", value: "totalPaymentOut", format: "$#,##0.000"},
                    { label: "Cash Amount", value: "cashAmount", format: "$#,##0.000"},
                    { label: "Credit Card", value: "creditAmount", format: "$#,##0.00"},
                    { label: "Debit Card", value: "debitAmount", format: "$#,##0.00"},
                    { label: "EBT", value: "ebtAmount", format: "$#,##0.00"},
                    { label: "House Account", value: "inHouseAmount", format: "$#,##0.00"},
                    { label: "Other Payment", value: "otherPayment", format: "$#,##0.00"},
                    { label: "Coupon", value: "couponAmount", format: "$#,##0.00"},
                    { label: "Spirit", value: "Spirit", format: "$#,##0.00"},
                    { label: "Beer/Wine", value: "BeerWine", format: "$#,##0.00"},
                    { label: "Grocery", value: "Grocery", format: "$#,##0.00"},
                    { label: "Inside Sales", value: "InsideSales", format: "$#,##0.00"},
                    { label: "Other", value: "Other", format: "$#,##0.00"},
                    { label: "Cigarette", value: "Cigarette", format: "$#,##0.00"},
                    ],
                    content: this.dailySales,
                }]
                
            let settings = {
                fileName: "VEECLi-DailySales-" + moment().format('YYYY-MM-DD-HH-mm-ss'), // Name of the resulting spreadsheet
                extraLength: 1, // A bigger number means that columns will be wider
                writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
                writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
                RTL: false, // Display the columns from right-to-left (the default value is false)
            }

            jsonAsXLSX(jsonAsXlsxData, settings) // Will download the excel file

        },
     
    },

    computed: {

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        formatCurrency(num) {
            return num | this.formatCurrency

        },

        columnTotals() {
            return [
                100, 200, 300, 400
            ];
        },

    },

    mounted() {

        this.user = this.getUser()
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]        

    }    
    
}
</script>

<style>
/* .scrollable {
    width: 90vw !important;
    padding-right: 10px;
} */
.fullwidth {
    width: 100%;
    
}
.boxheader {
    background-color: rgb(235, 244, 252);
}
.bordertop {
    border-top: 2px solid gray;
}
.borderbottom {
    border-bottom: 2px solid gray;
    border-color: lightgray;
}
.databox {
    background-color:aliceblue;
    margin: 10px;
    padding: 10px;
    box-shadow: 5px 5px #888888;
    vertical-align: top;
}
.topalign {
    vertical-align: top;
}
.box {
    margin: 5px;
}
</style>